import React, { Component } from "react";

import HeroSection from "../components/HeroSection/HeroSectionLiveStream";
import AuthHeader from "../components/Header/AuthHeader";
import FooterSection from "../components/Footer";

class LiveStream extends Component {
  render() {
    return (
      
      <React.Fragment>
        <AuthHeader/>
        <div className="main">
          <HeroSection />
        </div>  
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default LiveStream;
