import React from "react";
import { connect } from "react-redux";

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="download"
          className="video-promo ptb-100 background-img"
          style={{
            backgroundImage: "url('img/video-fbg.png')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container ">
            <div className="row justify-content-center">
             <div className="row align-items-center justify-content-between">
              <div className="col-md-12">
                <div className="hero-content- text-white">
                  <h1 className="text-white">
                    <span>Ready to view the live stream?</span>
                  </h1>

                  <a href="/livestream" className="btn app-store-btn page-scroll">
                    Click here.
                  </a>
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(Video);
