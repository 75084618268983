import React, { Component } from "react";

import HeroSection from "../components/HeroSection/HeroSectionMore";
import AuthHeader from "../components/Header/AuthHeader";
import FooterSection from "../components/Footer";

class More extends Component {
  render() {
    return (
      <React.Fragment>
        <AuthHeader></AuthHeader>
        <div className="main">
          <HeroSection />
        </div>  
        <FooterSection noSubscription={true} />

      </React.Fragment>
    );
  }
}

export default More;
