import React from "react";
import { connect } from "react-redux";
import { subscribe } from "../../actions/index";

import VideoPlayer from './../Video/VideoPlayer';
const videoJsOptions = {
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  
  sources: [{
    src:"https://celive-usea.streaming.media.azure.net//f1841693-5557-41e7-8a88-c778ad5014b9/6dc3eb27-4594-44d4-91e9-42e8fdc90444.ism/manifest(format=m3u8-aapl)",
    type:"application/x-mpegURL"
    }]
}

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      disableBtn: false,
      btnText: "Subscribe",
      sources: {
        type: 'video',
         sources: [
         {
             src: 'ZPt9dJw1Dbw',
             provider: 'youtube',
         },
     ],
      }  
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
 
  changeBtnText = btnText => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({ disableBtn: true });

    // // get action
    const subscribeAction = subscribe(this.state);

    // // Dispatch the contact from data
    this.props.dispatch(subscribeAction);

    // // added delay to change button text to previous
    setTimeout(
      function() {
        // enable the button
        this.setState({ disableBtn: false });

        // change to button name
        this.changeBtnText("Subscribe");

        // get action again to update state
        const subscribeAction = subscribe(this.state);

        // Dispatch the contact from data
        this.props.dispatch(subscribeAction);

        // clear form data
        this.setState({
          email: "",
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }
  
  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section ptb-100 background-img full-screen"
          style={{
            backgroundImage: "url('img/ce_wedding_3.JPG')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div className="video_box container raised">
          <div className="row align-items-center justify-content-center">
              <div className="col-md-12 col-lg-7">
                <div className="error-content text-center text-white">
                  <h2 className="text-white">Live Stream</h2>
                  <p>
                    View the live stream below. You can utilize our TV Apps,  AirPlay or Google Cast to view the stream on your TV
                  </p>
                </div>
                <VideoPlayer { ...videoJsOptions } />

              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state,
}))(HeroSection);
