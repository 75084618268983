import React from "react";
import { connect } from "react-redux";
import { login } from "../../actions/index";
import * as firebase from 'firebase';
const config = {
  apiKey: "AIzaSyAuR1yRv_vmZK6hUeCFRq-7cvA4P_z1RTA",
  authDomain: "carolina-elite-live.firebaseapp.com",
  databaseURL: "https://carolina-elite-live.firebaseio.com",
  projectId: "carolina-elite-live",
  storageBucket: "carolina-elite-live.appspot.com",
  messagingSenderId: "945259189118",
  appId: "1:945259189118:web:fda25c3e59e908c444192f",
  measurementId: "G-7FTVXPLQMK"
}

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    firebase.initializeApp(config);
    // const databaseRef = firebase.database().ref();
    // export const todosRef = databaseRef.child("todos")
    firebase.auth().signInAnonymously().catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log('No such document!');

      // ...
    });
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        var isAnonymous = user.isAnonymous;
        var uid = user.uid;
        console.log('User is signed!');

        
        // ...
      } else {
        // User is signed out.
        // ...        console.log('No such document!');

        console.log('User is signed  out!');

      }
      // ...
    });
    this.state = {
      hero: {},
      email: "",
      password: "",
      disableBtn: false,
      btnText: "Continue",
      errors: {
        fullName: '',
        email: '',
        passcode: '',
      },
      redirectToReferrer: false
  };

    this.handleSubmit = this.handleSubmit.bind(this);
  }



  changeBtnText = btnText => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSubmit(event) {
    event.preventDefault();

    // disable the button
    this.setState({ disableBtn: true });

    // // get action
    const loginAction = login(this.state);
    firebase.firestore().collection("emails").add({
      email:this.state.email
    });
    // const databaseRef = firebase.database().ref();
    // const todosRef = databaseRef.child("emails");
    // todosRef.push().set(this.state.email)

    if (this.state.password === "adot")  {
      
      window.location.href='/event';
      
    } else {
          
          // enable the button
          this.setState({ disableBtn: false });

          // change to button name
          this.changeBtnText("Continue");
  
          // get action again to update state
          const loginAction = login(this.state);
  
          // Dispatch the contact from data
          this.props.dispatch(loginAction);
  
          // clear form data
          this.setState({
            // email: "",
            password: "",
            errors: {passcode: "Invalid passcode please try again"},
          });
    }

    // // Dispatch the contact from data
    this.props.dispatch(loginAction);

    // // added delay to change button text to previous
    setTimeout(
      function() {
        // enable the button
        this.setState({ disableBtn: false });

        // change to button name
        this.changeBtnText("Continue");

        // get action again to update state
        const loginAction = login(this.state);

        // Dispatch the contact from data
        this.props.dispatch(loginAction);

        // clear form data
        this.setState({
          email: "",
          password: "",
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    const {errors} = this.state;
    
    return (
      <React.Fragment>
        <section className="hero-section full-screen gray-light-bg">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
                <div
                  className="bg-cover vh-100 ml-n3 background-img"
                  style={{
                    backgroundImage: "url('img/ce_wedding_3.JPG')",
                  }}
                >
                  <div className="position-absolute login-signup-content">
                    <div className="position-relative text-white col-md-12 col-lg-7">
                      <h2 className="text-white">Please enter your event passcode !</h2>
                      <p className="lead">
                        You're almost there! View our live stream, photo stream and more!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                <div className="login-signup-wrap px-4 px-lg-5 my-5">
                  <h1 className="text-center mb-1">Enter Virtual Event</h1>
                  <p className="text-center mb-5">
                  Don't know your event passcode? Contact your event host to access the event virtually.
                  </p>

                  <form
                    className="login-signup-form"
                    method="post"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="form-group">
                      <label className="pb-1">Email Address</label>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-email color-primary"></span>
                        </div>
                        <input
                          value={this.state.email}
                          onChange={e => this.handleFormValueChange("email", e)}
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col">
                          <label className="pb-1">Event Code</label>
                        </div>
                        {/* <div className="col-auto">
                          <a
                            href="password-reset.html"
                            className="form-text small text-muted"
                          >
                            Forgot password?
                          </a>
                        </div> */}
                      </div>
                      <div className="input-group input-group-merge">
                        <div className="input-icon">
                          <span className="ti-lock color-primary"></span>
                        </div>
                        <input
                          value={this.state.password}
                          onChange={e =>
                            this.handleFormValueChange("password", e)
                          }
                          type="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter your event passcode"
                          required
                        />
                      </div>
                      {errors.passcode.length > 0 && <span className='error'>{errors.passcode}</span>}
                    </div>
                    <div className="form-check d-flex align-items-center text-center">
                      <input
                        checked={this.state.isAgreed}
                        onChange={e =>
                          this.handleFormValueChange("isAgreed", e)
                        }
                        type="checkbox"
                        name="isAgreed"
                        className="form-check-input mt-0 mr-3"
                        id="ckbAgree"
                        required
                      />
                      <label className="form-check-label small" htmlFor="ckbAgree">
                        I agree your <a href="https://www.carolinaeliteevents.com/terms">terms and conditions</a>
                      </label>
                    </div>
                    <button
                      className="btn btn-lg btn-block solid-btn border-radius mt-4 mb-3"
                      id="signIn"
                      disabled={this.state.disableBtn}
                      onClick={() => {
                        this.changeBtnText("Entering...");
                      }}
                    >
                      {this.state.btnText}
                    </button>

                    {/* <p className="text-center">
                      <small className="text-muted text-center">
                        Don't have an account yet?{" "}
                        <a href="signup2">Sign up</a>.
                      </small>
                    </p> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state,
}))(HeroSection);
