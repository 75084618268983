import React from "react";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section id="features" className="feature-section ptb-100">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-4">
                <div className="download-img d-flex align-bottom">
                  <img
                    src="img/image-10.png"
                    alt="download"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-7">
                <div className="feature-contents section-heading">
                  <h2>
                    Feature-Rich App Suite   <br />
                    <span>Creating an unforgettable experience for guests who can't make it</span>
                  </h2>
                  <p>
                  We are an Award-Winning Full Service Event Production Company with years of experience servicing weddings and private events. Leave it to us to take your event to the next level!
                  </p>

                  <div className="feature-content-wrap">
                    <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                      <li className="nav-item">
                        <a
                          className="nav-link active h6"
                          href="#tab6-1"
                          data-toggle="tab"
                        >
                          <span className="ti-video-clapper"></span>
                          Live Stream
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-2"
                          data-toggle="tab"
                        >
                          <span className="ti-camera"></span>
                          Photo Stream
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-3"
                          data-toggle="tab"
                        >
                          <span className="ti-microphone"></span>
                          Virtual Dance Party
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link h6"
                          href="#tab6-4"
                          data-toggle="tab"
                        >
                          <span className="ti-menu"></span>
                          And More
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content feature-tab-content">
                      <div className="tab-pane active" id="tab6-1">
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">Full HD and 4K Streaming Options</p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  Multiple Camera Angles
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  Professional Cinematographers
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  Gimble-Stablilzed Video
                                </p>
                              </div>
                            </div>
                          </li>
                          {/* <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Memories in Motion
                                </p>
                              </div>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                     
                      <div className="tab-pane" id="tab6-2">
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">Professional Photography Team</p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  High-Quality Photos Streamed directly to guests via our apps
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  Professional Grade Full Frame Cameras
                                </p>
                              </div>
                            </div>
                          </li>
                          {/* <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  24 Megapixel Images
                                </p>
                              </div>
                            </div>
                          </li> */}
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Stunning photos that will blow your guests away
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      {/* <div className="tab-pane" id="tab6-2">
                        <div className="single-feature">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-camera rounded mr-3 icon icon-color-1"></span>
                            <h5 className="mb-0">Easy to use</h5>
                          </div>
                          <p>
                            Synergistically deliver next-generation
                            relationships whereas bleeding-edge resources.
                            Continually pontificate stand-alone benefits
                            whereas.
                          </p>
                        </div>
                        <div className="single-feature mb-4">
                          <div className="d-flex align-items-center mb-2">
                            <span className="ti-alarm-clock rounded mr-3 icon icon-color-2"></span>
                            <h5 className="mb-0">Increase conversion</h5>
                          </div>
                          <p>
                            Phosfluorescently empower compelling intellectual
                            capital and revolutionary web services. Compellingly
                            develop cross-media.
                          </p>
                        </div>
                      </div> */}

                      <div className="tab-pane" id="tab6-3">
                        <ul className="list-unstyled">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">Professional DJ Team</p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  High-Quality Audio run by certified audio engineers
                                </p>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  State of the Art Dance Lighting
                                </p>
                              </div>
                            </div>
                          </li>
                          {/* <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                  Uninterupted Streams
                                </p>
                              </div>
                            </div>
                          </li> */}
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div>
                                <div className="badge badge-circle badge-primary mr-3">
                                  <span className="ti-check"></span>
                                </div>
                              </div>
                              <div>
                                <p className="mb-0">
                                Your special day celebrated the right way!
                                </p>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      {/* <div className="tab-pane" id="tab6-3">
                        <div className="row">
                          <div className="col single-feature mb-4">
                            <div className="d-flex align-items-center mb-2">
                              <span className="ti-bar-chart rounded mr-3 icon icon-color-2"></span>
                              <h5 className="mb-0">Increase conversion</h5>
                            </div>
                            <p>
                              Display recent conversions, build credibility and
                              trust.
                            </p>
                          </div>
                          <div className="col single-feature mb-4">
                            <div className="d-flex align-items-center mb-2">
                              <span className="ti-stats-up rounded mr-3 icon icon-color-3"></span>
                              <h5 className="mb-0">Product analytics</h5>
                            </div>
                            <p>
                              A top promo bar that counts down until a few
                              discounts.
                            </p>
                          </div>
                        </div> */}
                      {/* </div> */}
                      <div className="tab-pane" id="tab6-4">
                        <p>
                        At Carolina Elite, we have integrated features into our apps to enhance the guest experience from A-Z, like Virtual Guestbooks, Photobooths, Push Notifications, Song Requests, and much more. If you have any special requests, our team will likely be able to accommodate them.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
