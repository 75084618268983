import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes

import Theme4 from "../themes/theme4";
import Login from "../themes/login";
import SignUp2 from "../themes/signup2";
import NotFound from "../themes/404";
import ComingSoon from "../themes/comingSoon";
import Blog from "../themes/blog";
import PhotoStream from "../themes/photostream"
import LiveStream from "../themes/livestream"
import More from "../themes/more"
import Schedule from "../themes/schedule"

export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={Theme4} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup2" component={SignUp2} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/comingSoon" component={ComingSoon} />
            <Route exact path="/livestream" component={LiveStream} />
            <Route exact path="/photostream" component={PhotoStream} />
            <Route exact path="/schedule" component={Schedule} />
            <Route exact path="/more" component={More} />
            <Route exact path="/event" component={Blog} />
            <Route exact path='*' component={NotFound} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
