import React, { Component } from "react";

import HeroSection from "../components/HeroSection/HeroSectionPhotoStream";
import AuthHeader from "../components/Header/AuthHeader";
import FooterSection from "../components/Footer";

class PhotoStream extends Component {
  render() {
    return (
      <React.Fragment>
                <AuthHeader/>

        <div className="main">
          <HeroSection />
        </div>  
        <FooterSection noSubscription={true} />

      </React.Fragment>
    );
  }
}

export default PhotoStream;
