import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSection4";
import PromoSection from "../components/PromoSection";
import FeatureSection from "../components/Features/Feature3";
import VideoSection from "../components/Video";
import TrustedCompany from "../components/TrustedCompany/TrustedCompany";
import ContactSection from "../components/Contact";
import SubscribeSection from "../components/Subscribe";
import FooterSection from "../components/Footer";
import Faq from "../components/Faq/faq";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header />
        <div className="main">
          <HeroSection />
          <PromoSection />
          <TrustedCompany />
          <FeatureSection />
          <VideoSection showDownloadBtn={false} />
          <Faq/>
          <ContactSection />
        </div>
        <SubscribeSection />
        <FooterSection />
      </React.Fragment>
    );
  }
}

export default Theme;
