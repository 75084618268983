import React, { Component } from "react";

import AuthHeader from "../components/Header/AuthHeader";
import HeroSection from "../components/HeroSection/HeroSectionBlog";
import FooterSection from "../components/Footer";
import Promo from "../components/PromoSection/Promo2"
import VideoSection from "../components/Video/indexLink"
class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <AuthHeader />
        <div className="main">
          <HeroSection />
          <Promo/>
          <VideoSection />

          {/* <BlogSection /> */}
        </div>
        <FooterSection noSubscription={true} />
      </React.Fragment>
    );
  }
}

export default Theme;
