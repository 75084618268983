import React from "react";
import { connect } from "react-redux";

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="download"
          className="video-promo ptb-100 background-img"
          style={{
            backgroundImage: "url('img/ce_tesla.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover"
          }}
        >
          <div className="container ptb-100">
            <div className="row video-promo-content justify-content-center">
              {/* <div className="col-md-6">
                <div className="video-promo-content mt-4 text-center">
                  <a
                    href="https://www.youtube.com/watch?v=9No-FiEInLA"
                    className="popup-youtube video-play-icon d-inline-block"
                  >
                    <span className="ti-control-play"></span>{" "}
                  </a>
                  {/* <h5 className="mt-4 text-white">Watch video overview</h5> 
                  {this.props.showDownloadBtn &&
                    this.props.showDownloadBtn === true && (
                      <div className="download-btn mt-5">
                        <a href="/#" className="btn google-play-btn mr-3">
                          <span className="ti-android"></span> Google Play
                        </a>
                        <a href="/#" className="btn app-store-btn">
                          <span className="ti-apple"></span> App Store
                        </a>
                      </div>
                    )}
                </div>
              </div>
           
            */}
             <div className="  ">
              <div className="col-md-12">
                <div className=" text-white">
                  <h1 className="text-white">
                    <span>Already have an event passcode?</span>
                  </h1>
                  {/* <p className="lead">
                    Carolina Elite has created it's own pripriety apps that allow guests who can't make it in person to join virtually.
                  </p> */}

                  <a href="/login" className="btn app-store-btn page-scroll">
                    Enter your event here.
                  </a>
                </div>
              </div>
            </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(Video);
