module.exports = {
  hero: {
    title: "Brainstorming for desired perfect Usability",
    description:
      "Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!",
    bgImage: "img/app-hero-bg.jpg"
  },
  promo: {
    title: "What services can we provide?",
    description:
      "Carolina Elite provides a variety of services that will enhance the experience of guests in person and virtually",
    items: [
      {
        title: "Entertainment",
        description:
          "We create unforgettable event with Pro DJ Dance Parties, Photobooths & More!",
        icon: "ti-music-alt text-white"
      },
      {
        title: "Photography",
        description:
        "Creating long-lasting memories of your special day",
        icon: "ti-camera text-white"
      },
      {
        title: "Videography",
        description:
        "Capturing your special day and sharing it live with friends and family",
        icon: "ti-video-camera text-white"
      }
    ]
  },
  aboutUs: {
    title: {
      bold: "Our App's are accessible on most platforms",
      normal: ""
    },
    description:
      "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
    items: [
      {
        title: "Clean Design",
        description:
          "Increase sales by showing true dynamics of your website",
        icon: "ti-vector text-white"
      },
      {
        title: "Secure Data",
        description:
          "Build your online store’s trust using Social Proof & Urgency",
        icon: "ti-lock text-white"
      },
      {
        title: "Retina Ready",
        description:
          "Realize importance of social proof in customer’s purchase decision",
        icon: "ti-eye text-white"
      }
    ]
  },
  price: {
    title: "Pricing Packages",
    description:
      "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
    packages: [
      {
        price: "$29",
        license: "Basic",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "2 months technical support",
          "2+ profitable keyword"
        ]
      },
      {
        price: "$50",
        license: "Popular",
        isFeatured: true,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "6 months technical support",
          "30+ profitable keyword"
        ]
      },
      {
        price: "$120",
        license: "Extended",
        isFeatured: false,
        features: [
          "Push Notifications",
          "Data Transfer",
          "SQL Database",
          "Search & SEO Analytics",
          "24/7 Phone Support",
          "1 year technical support",
          "70+ profitable keyword"
        ]
      }
    ]
  },
  contact: {
    title: "Contact with us",
    description:
      "It's very easy to get in touch with us. Just use the contact form or give us a call. We are happy to discuss your event and how Carolina Elite might be able to take your event to the next level.",

    office: "",
    address: "",
    phone: "(919) 473-6573",
    email: "info@carolinaeliteevents.com"
  },
  trustedCompany: [
    "img/client-1-color.png",
    "img/client-2-color.png",
    "img/client-7-color.png",
    "img/client-8-color.png",
    "img/client-6-color.png",
    "img/client-3-color.png",
    "img/client-4-color.png",
    "img/client-5-color.png"
  ],
  trustedCompanyGray: [
    "img/client-1-gray.png",
    "img/client-2-gray.png",
    "img/client-3-gray.png",
    "img/client-4-gray.png",
    "img/client-5-gray.png"
  ],
  trustedCompanyAboutUs: {
    title: "How can guests get access?",
    description: "Our apps are accessible on most platforms making your guests experience to tune in easier than ever.",
    companies: [
      "img/client-9-color.png",
      "img/client-8-color.png",
      "img/client-3-color.png",
      "img/client-5-color.png",
      // "img/client-2-color.png",
      "img/client-1-color.png",
      "img/client-6-color.png",
      "img/client-4-color.png",
      "img/client-7-color.png",
    ]
  },
  teamMember: {
    members: [
      {
        name: "Edna Mason",
        designation: "Senior Designer",
        image: "img/team-4.jpg"
      },
      {
        name: "Arabella Ora",
        designation: "Senior Designer",
        image: "img/team-1.jpg"
      },
      {
        name: "John Charles",
        designation: "Senior Designer",
        image: "img/team-2.jpg"
      },
      {
        name: "Jeremy Jere",
        designation: "Senior Designer",
        image: "img/team-3.jpg"
      },
    ]
  },
  video: {
    link: "https://www.youtube.com/watch?v=9No-FiEInLA"
  }
};
